/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 418px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 72.28915662650603%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEDBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAHRV01yYH//xAAaEAADAQADAAAAAAAAAAAAAAAAAQIDERIi/9oACAEBAAEFAtpt1n4JfZcaCWglZ//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/Aaf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAcEAADAAEFAAAAAAAAAAAAAAAAATECEDJigZH/2gAIAQEABj8CrWPEr703LwuJUf/EABwQAQACAwADAAAAAAAAAAAAAAEAESExYUGB8f/aAAgBAQABPyFMgB0ssaGHVr+QbCNq471Ar83IUz6Sf//aAAwDAQACAAMAAAAQ5P8A/8QAFhEBAQEAAAAAAAAAAAAAAAAAEQBB/9oACAEDAQE/EEGTf//EABYRAQEBAAAAAAAAAAAAAAAAAAARAf/aAAgBAgEBPxCYj//EAB0QAQACAgMBAQAAAAAAAAAAAAERIQBBMVFxYcH/2gAIAQEAAT8Qrq1C/HEke4+S2Bmdih+is67sMM37vB8xo5I1eUu7LDL33jofdUwZ/9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Denver Broncos\"\n        title=\"Denver Broncos\"\n        src=\"/static/03fd260bbf29a20aa3f657a9cb656235/88ad2/denver-broncos.jpg\"\n        srcset=\"/static/03fd260bbf29a20aa3f657a9cb656235/4d5fb/denver-broncos.jpg 166w,\n/static/03fd260bbf29a20aa3f657a9cb656235/558f0/denver-broncos.jpg 333w,\n/static/03fd260bbf29a20aa3f657a9cb656235/88ad2/denver-broncos.jpg 418w\"\n        sizes=\"(max-width: 418px) 100vw, 418px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Joining the AFL in ", React.createElement(_components.strong, null, "1960"), " the ", React.createElement(_components.strong, null, "Denver Broncos"), " became an official pro football team when Bob Howsam was granted the franchise.  The team’s name was contrived through a ", React.createElement("span", {
    style: {
      textDecoration: `underline`
    }
  }, "naming contest"), " via the people of Denver in 1960.  For most of its time in football the Broncos have played at ", React.createElement(_components.strong, null, "Mile High stadium"), ".   The stadium is aptly named as it is indeed exactly 1 mile above sea level.  Interestingly, they have the ", React.createElement("span", {
    style: {
      textDecoration: `underline`
    }
  }, "best home game record"), " in the last 30 years and have never been shut out at home after 400 games.  In addition, the fans are particularly dedicated as every home game has been ", React.createElement("span", {
    style: {
      textDecoration: `underline`
    }
  }, "sold out"), " since 1970.  The Broncos have the distinction of being the first AFL team to ever beat an NFL team.  In total they have been to **6 Super Bowls **and have been the victor in 2 of them.  The Broncos got their start in Denver and have never left in over 50 years."), "\n", React.createElement(_components.p, null, "Denver Broncos Logo"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
